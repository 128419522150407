import { ApiStatus } from '../../api/constants/ApiConstants'

const { success, error } = ApiStatus

export default {
  setArticle: (state, payload) => {
    state.article = payload
    state.articleLoadedStatus = success
  },
  setArticleFailure: state => {
    state.article = {}
    state.articleLoadedStatus = error
  },
  setArticleLoadingStatus: (state, payload) => {
    state.articleLoadedStatus = payload
  }
}
